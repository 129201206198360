/**
 * Accordion row (close / open accordion in the same row)
 */
export default function AccordionRow() {
    // Refs
    const accordionItems = document.querySelectorAll('.accordion-item');

    // Media query and listener
    const isTowColumn = window.matchMedia('(min-width: 768px)');
    isTowColumn.addEventListener('change', toggleSibling);

    toggleSibling();

    function toggleSibling() {
        for (const item of accordionItems) {
            item.addEventListener('click', () => {
                console.log('is ', isTowColumn.matches);

                // When is single column, do nothing
                if (!isTowColumn.matches) return;

                // Get next sibling and try on previous sibling if
                // next sibling is not found
                let sibling = item.parentNode.nextElementSibling;
                if (!sibling) {
                    sibling = item.parentNode.previousElementSibling;
                }

                sibling
                    .querySelector('.accordion-item .accordion-button')
                    .click();
            });
        }
    }
}
