/**
 * Main Site Menu
 * - Hanburger Open/Close Menu
 * - Main Menu Logic
 * - - Desktop: side submenu
 * - - Mobile: drilldown
 */
export default function siteMenu() {
  /**
   * Hanburger Open/Close Menu
   */
  const hamburgerBtns = document.querySelectorAll(".site-hamburger");
  const menu = document.querySelector(".fullwidth-menu");
  const menuNavigation = menu.querySelector(".main-site-menu");

  for (const element of hamburgerBtns) {
    element.addEventListener("click", () => {
      menu.classList.toggle("open");
      document.body.classList.toggle("menu-open");
    });
  }

  /**
   * Main Menu Logic
   * - Desktop: side submenu on click
   * - Mobile: drilldown submenu on click
   */

  // Media query and listener for desktop / mobile switch
  const isDesktopMenu = window.matchMedia("(min-width: 768px)");
  isDesktopMenu.addEventListener("change", () => menuSetup(isDesktopMenu, menu, menuNavigation));

  // Initial setup
  menuSetup(isDesktopMenu, menu, menuNavigation);
}

/**
 * Main Menu Logic
 * - Desktop: side submenu on click
 * - Mobile: drilldown submenu on click
 */
function menuSetup(isDesktopMenu, menu, menuNavigation) {
  if (isDesktopMenu.matches) {
    /**
     * Main Menu Logic on Desktop
     */

    // Reset menu references
    menuNavigation.classList.add("desktop");
    menuNavigation.classList.remove("mobile");

    // References
    const menuImageWrapper = menu.querySelector(".menu-side-image");
    const menuLinksLevOne = menu.querySelectorAll(".main-site-menu.desktop > li > a");
    const menuLinksLevTwo = menu.querySelectorAll(".main-site-menu.desktop > li > .sub-menu > li > a");
    const menuSubmenus = menu.querySelectorAll(".sub-menu");

    // Set Default Side Image
    let defaultImage = menu.querySelector(".main-site-menu.desktop > li.current-menu-item img, .main-site-menu.desktop > li.current-menu-parent img, .main-site-menu.desktop > li.current-menu-ancestor img");

    if (!defaultImage) {
      defaultImage = menu.querySelector(".main-site-menu.desktop li:first-child img");
    }

    if(defaultImage) {
        const src = defaultImage.getAttribute("src");
        menuImageWrapper.querySelector("img").setAttribute("src", src);
    }

    // Toggle Submenus and set active image
    // Submenu Level 1
    toggleSubmenu(menuLinksLevOne, ".main-site-menu.desktop > li", menu, menuSubmenus, menuImageWrapper);

    // Submenu Level 2
    toggleSubmenu(menuLinksLevTwo, ".main-site-menu.desktop > li > .sub-menu > li", menu, menuSubmenus, menuImageWrapper);
  } else {
    /**
     * Main Menu Logic on Mobile
     */
    menuNavigation.classList.add("mobile");
    menuNavigation.classList.remove("desktop");

    const mainNavigation = document.querySelector(".main-site-menu.mobile");
    const slideLinks = mainNavigation.querySelectorAll('a[href="#"]');

    for (const link of slideLinks) {
      link.addEventListener("click", function (e) {
        e.preventDefault();

        const ul = this.nextElementSibling;

        // Add back link if not exists
        if (ul.dataset.addBack !== "true") {
          createBackLink(ul, this.textContent);
        }

        ul.classList.add("active");
      });
    }
  }
}

/**
 * Toggle Submenus and set active image
 */

function toggleSubmenu(list, clearSelector, menu, menuSubmenus, menuImageWrapper) {
  for (const element of list) {
    element.addEventListener("mouseenter", (e) => {
      const submenu = element.nextElementSibling;

      // Menu item with submenu
      if (submenu) {
        element.classList.add("parent-active");
        e.preventDefault();
        submenu.classList.add("open");

        // Close other submenus
        const siblingsLi = menu.querySelectorAll(clearSelector);
        for (const li of siblingsLi) {
          if (li !== element.parentNode) {
            const submenus = li.querySelectorAll(".sub-menu");
            if (submenus.length > 0) {
              submenus.forEach((submenu) => {
                submenu.classList.remove("open");
              });
            }
          }
        }

        // Update previuos sibling links
        menuSubmenus.forEach((submenu) => {
          if (submenu.classList.contains("open")) {
            submenu.previousElementSibling.classList.add("parent-active");
          } else {
            submenu.previousElementSibling.classList.remove("parent-active");
          }
        });
      } else {
        // Menu item without submenu
        const ul = element.closest("ul");
        // link of first level
        if (ul.classList.contains("main-site-menu")) {
          menuSubmenus.forEach((submenu) => {
            submenu.previousElementSibling.classList.remove("parent-active");
            submenu.classList.remove("open");
          });
        } else if (ul.classList.contains("sub-menu")) {
          // link of second level reset siblings open submenus
          const siblingsLi = ul.querySelectorAll("li.menu-item-has-children");
          for (const li of siblingsLi) {
            li.querySelector("a").classList.remove("parent-active");
            li.querySelector(".sub-menu").classList.remove("open");
          }
        }
      }

      // Set active image
      const image = element.querySelector("img");

      if (!image) {
        return;
      }

      const topImage = document.createElement("img");
      topImage.classList.add("image-fit", "absolute");
      topImage.setAttribute("src", image.getAttribute("src"));
      menuImageWrapper.prepend(topImage);

      // animate the opacity of the old image to 0
      let opacity = 1;
      const speed = 0.035;
      const oldImages = menuImageWrapper.querySelectorAll("img:not(:first-child)");

      function fadeOut() {
        opacity -= speed;
        // remove the old images from the DOM
        for (const img of oldImages) {
          img.style.opacity = opacity;
        }

        if (opacity <= 0) {
          for (const img of oldImages) {
            img.remove();
          }
        } else {
          requestAnimationFrame(fadeOut);
        }
      }
      fadeOut(); // avvia l'animazione
    });
  }
}

/**
 * Create Back Link
 */

function createBackLink(ul, linkText) {
  const backLinkLi = document.createElement("li");
  const backLink = document.createElement("a");
  backLink.classList.add("back-link");
  backLink.setAttribute("href", "#");
  backLink.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="back-icon">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9.75 14.25 12m0 0 2.25 2.25M14.25 12l2.25-2.25M14.25 12 12 14.25m-2.58 4.92-6.374-6.375a1.125 1.125 0 0 1 0-1.59L9.42 4.83c.21-.211.497-.33.795-.33H19.5a2.25 2.25 0 0 1 2.25 2.25v10.5a2.25 2.25 0 0 1-2.25 2.25h-9.284c-.298 0-.585-.119-.795-.33Z" />
        </svg>
        ${linkText}`;

  backLink.addEventListener("click", function (e) {
    e.preventDefault();
    ul.classList.remove("active");
  });

  backLinkLi.append(backLink);
  ul.prepend(backLinkLi);
  ul.dataset.addBack = "true";
}
