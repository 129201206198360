import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

/**
 * Site Sliders
 *
 * - Site Carousel
 * - Site Slider (with lightbox)
 */
export default function siteSliders() {
    /**
     * Site Carousel
     */
    const carousels = document.querySelectorAll('.site-carousel');

    for (const carousel of carousels) {
        const slidesPerView = carousel.dataset.elements || 7;
        const isTowColsMobile = carousel.classList.contains('two-cols-mobile');

        new Swiper(carousel, {
            // Modules
            modules: [Autoplay, Pagination],
            // autoplay: {
            //     delay: 5000,
            // },
            speed: 700,
            loop: true,
            slidesPerView: isTowColsMobile ? 2 : 3,
            spaceBetween: 20,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                576: {
                    slidesPerView: 3,
                    spaceBetween: 32,
                },
                768: {
                    slidesPerView: isTowColsMobile ? 4 : 5,
                },
                991: {
                    slidesPerView,
                },
            },
        });
    }

    /**
     * Site Slider with lightbox
     */
    const siteSlider = document.querySelector('.site-slider');
    if (!siteSlider) return;

    new Swiper(siteSlider, {
        // Modules
        modules: [Autoplay, Navigation],
        autoplay: {
            delay: 5000,
        },
        speed: 700,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    // Lightbox
    const openLightboxBtn = siteSlider.querySelector('.lightbox-button');

    const sliderImages = [
        ...siteSlider.querySelectorAll('.swiper-slide .image'),
    ];
    const sources = sliderImages.map(image => image.src);

    const lightbox = new FsLightbox();
    lightbox.props.sources = sources;

    openLightboxBtn.addEventListener('click', () => {
        lightbox.open();
    });
}
