import 'bootstrap/js/dist/collapse'; // Collapse: for navbar mobile collapse and accordion
import bodyLoaded from './partials/bodyLoaded';
import scrollHeader from './partials/scrollHeader';
import siteSliders from './partials/sliders';
import siteMenu from './partials/siteMenu';
import scrollAnimations from './partials/scrollAnimations';
import AccordionRow from './partials/accordionRow';

/**
 * MAIN APP JS LOGIC
 */

// CHECK IF DOCUMENT IS READY
if (document.readyState !== 'loading') {
    docIsReady();
} else {
    document.addEventListener('DOMContentLoaded', docIsReady);
}

// DOCUMENT READY FUNCTION
function docIsReady() {
    /**
     * Remove body onload layer
     */
    bodyLoaded();

    /**************************************************************************************
        CUSTOM CODE
     **************************************************************************************/

    /**
     * Check if the header is scrolled
     */
    scrollHeader();

    /**
     * Site menu toggle
     */
    siteMenu();

    /**
     * Site sliders
     */
    siteSliders();

    /**
     * Site scroll animations
     */
    scrollAnimations();

    /**
     * Accordion row (close / open accordion in the same row)
     */
    AccordionRow();
} // End Document Ready
